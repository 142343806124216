body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@font-face {
  font-family: 'Tisa Sans Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Regular'), url('../public/fonts/Tisa Sans Pro Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Italic'), url('../public/fonts/Tisa Sans Pro Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Extra Light';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Extra Light'), url('../public/fonts/Tisa Sans Pro ExtraLight.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Extra Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Extra Light Italic'), url('../public/fonts/Tisa Sans Pro ExtraLight Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Light'), url('../public/fonts/Tisa Sans Pro Light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Light Italic'), url('../public/fonts/Tisa Sans Pro Light Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Medium'), url('../public/fonts/Tisa Sans Pro Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Medium Italic'), url('../public/fonts/Tisa Sans Pro Medium Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Bold'), url('../public/fonts/Tisa Sans Pro Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Bold Italic'), url('../public/fonts/Tisa Sans Pro Bold Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Extra Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Extra Bold'), url('../public/fonts/Tisa Sans Pro ExtraBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Extra Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Extra Bold Italic'), url('../public/fonts/Tisa Sans Pro ExtraBold Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Black Italic'), url('../public/fonts/Tisa Sans Pro Black Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Tisa Sans Pro Black';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Black'), url('../public/fonts/Tisa Sans Pro Black.woff') format('woff');
  }